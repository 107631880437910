import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader/Loader";
import useSlider from "../../hooks/useSlider";
import { deleteCompany, getAllCompany } from "../../services/services";
import { Search } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import { noSpecialChars } from "../../helper/helper";

const CompanyManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSlider = useSlider();
  const [showDelete, setShowDelete] = useState(false);
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const { data, refetch } = useQuery({
    queryKey: ["company-list", page, limit],
    queryFn: async () => {
      const resp = await getAllCompany(page, limit, search);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCompany(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">
              {t("companyManagement.companyManagement")}
            </h2>
          </Col>
          <Col className="text-end" xl={6}>
            <Link className="userBtn btn-theme" to="../addnewcompany">
              {t("companyManagement.addNew")}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="sidebarBox mt-3">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive companyManagTable tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("companyManagement.companyName")}</th>
                <th>{t("companyManagement.totalBrands")}</th>
                <th>{t("globals.createdAt")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.companies && data?.companies?.length > 0 ? (
                data?.companies?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>{item?.name}</td>
                      <td>{item?.assosciatedBrands}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        <Link to={`../companydetails/${item?.uuid}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <img
                          src="/images/Edit.svg"
                          className="img-fluid actionIcon"
                          onClick={() => {
                            navigate(
                              `../companylocation/${item?.uuid}?id=${item?._id}`
                            );
                          }}
                        />
                        <img
                          onClick={() => setShowDelete(item?._id)}
                          src="/images/Delete.svg "
                          className="img-fluid actionIcon"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    <h4>{t("globals.noDataFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.companies?.length}
        />
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default CompanyManagement;
