import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogOut, SidebarIcon, UserIcon } from "../SvgIcons/allIcons";
import LogoutModal from "../authentication/LogoutModal";
import useDetails from "../hooks/useDetails";
import useSlider from "../hooks/useSlider";
import { slider } from "../redux/features/sliderSlice";
import "./sidebar/sidebar.css";

const AdminHeader = ({ showFullHeader }) => {
  const data = useDetails();
  const dispatch = useDispatch();
  const isSlider = useSlider();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <header
      className={
        showFullHeader ? "header close additional-class" : "header open"
      }
    >
      <ul>
        <div className="navicon d-none">
          <span
            onClick={() => {
              if (isSlider) {
                dispatch(slider(false));
              } else {
                dispatch(slider(true));
              }
            }}
          >
            <SidebarIcon />
          </span>
        </div>
        <div className="headToptext">
          <p className="mb-0 font-20">
            <span>{t("header.heyName", { name: data?.userInfo?.name })} </span>
            {t("header.welcomeBack")}
          </p>
        </div>
        <div className="header-right">
          <div className="admin-drop">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="img-ic">
                  <img
                    src={
                      data?.userInfo?.profilePhoto
                        ? data?.userInfo?.profilePhoto
                        : "/images/profile.jpg"
                    }
                    alt="Image"
                  />
                </span>
                <label> {data?.userInfo?.name}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/profile")}>
                  <UserIcon />
                  {t("header.myProfile")}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  <LogOut />
                  {t("header.logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </ul>
      <LogoutModal show={show} handleClose={() => setShow(false)} />
    </header>
  );
};

export default AdminHeader;
