import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSlider from "../../hooks/useSlider";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDetails from "../../hooks/useDetails";
import { constant } from "../../utils/constants";
import DeleteModal from "../../components/DeleteModal";
import { deleteDataList, getDataList } from "../../services/services";
import moment from "moment";
import { toastAlert } from "../../utils/SweetAlert";
import i18next from "i18next";
import CheckPermission from "../../components/CheckPermission";

const ReceivingList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const details = useDetails();
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit],
    queryFn: async () => {
      const resp = await getDataList(
        page,
        limit,
        search,
        constant.DATA_LIST.RECEIVING_LIST,
        details?.companyUID,
        true,
        true
      );
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, details?.companyUID),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("receiving.receivingList")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme me-2">
              {t("receiving.export")}
            </button>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS}
            >
              <Link className="userBtn btn-theme" to="../addreceivinglist">
                {t("receiving.addNew")}
              </Link>
            </CheckPermission>
          </Col>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th> {t("receiving.receivingListName")}</th>
                <th> {t("receiving.deliveryReceivingDate")}</th>
                <th> {t("receiving.vendorDistributor")}</th>
                <th> {t("receiving.item")}</th>
                <th> {t("receiving.unitMeasure")}</th>
                <th> {t("receiving.packCount")}</th>
                <th> {t("receiving.qtyToReceive")}</th>
                <th> {t("receiving.receivedLocaiton")}</th>
                <CheckPermission
                  permission={constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS}
                >
                  {" "}
                  <th> {t("globals.action")}</th>
                </CheckPermission>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>
                        {moment(item?.details?.deliveryDate).format("ll")}
                      </td>
                      <td>{item?.details?.vendorName}</td>
                      <td>
                        {i18next?.language == "es"
                          ? item?.itemDetails?.es_name
                          : item?.itemDetails?.en_name}
                      </td>
                      <td>{item?.details?.measureUnit}</td>
                      <td>{item?.details?.packCount}</td>
                      <td>{item?.details?.receivedQuantity}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <CheckPermission
                        permission={
                          constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS
                        }
                      >
                        <td>
                          <Link
                            to={`../addreceivinglist?id=${item?._id}`}
                            role="button"
                          >
                            <img src="./images/Edit.svg" alt="" />
                          </Link>

                          <img
                            src="./../images/Delete.svg"
                            alt="delete"
                            onClick={() => setShowDelete(item?._id)}
                            role="button"
                          />
                        </td>
                      </CheckPermission>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("receiving.noReceivingList")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.datalists?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default ReceivingList;
