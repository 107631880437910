import React from "react";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa6";
import { defaultFonts, labelTypes } from "../../utils/labelConstants";

const FormatEditor = (props) => {
  const { selectedElement, setItem, item, preview, subItem } = props;

  const handleChangeFontSize = (size) => {
    setItem((prevItems) =>
      prevItems.map((item) => {
        if (
          subItem &&
          item.id === selectedElement?.id &&
          item?.type == labelTypes.nutritional_fields
        ) {
          return {
            ...item,
            subItem: item.subItem.map((sub) =>
              sub.id === subItem?.id
                ? {
                    ...sub,
                    input: {
                      ...sub.input,
                      style: {
                        ...sub.input.style,
                        fontSize: size + "px",
                      },
                    },
                  }
                : sub
            ),
          };
        }

        if (item.id === selectedElement?.id) {
          return {
            ...item,
            input: {
              ...item.input,
              style: {
                ...item.input.style,
                fontSize: size + "px",
              },
            },
          };
        }
        return item;
      })
    );
  };

  const handleLineHeight = (height) => {
    setItem((prevItems) =>
      prevItems.map((item) => {
        if (
          subItem &&
          item.id === selectedElement?.id &&
          item?.type === labelTypes.nutritional_fields
        ) {
          return {
            ...item,
            subItem: item.subItem.map((sub) =>
              sub.id === subItem?.id
                ? {
                    ...sub,
                    input: {
                      ...sub.input,
                      style: {
                        ...sub.input.style,
                        lineHeight: height + "px",
                      },
                    },
                  }
                : sub
            ),
          };
        }

        if (item.id === selectedElement?.id) {
          return {
            ...item,
            input: {
              ...item.input,
              style: {
                ...item.input.style,
                lineHeight: height + "px",
              },
            },
          };
        }

        return item;
      })
    );
  };

  const handleChangeFont = (val) => {
    setItem((prevItems) =>
      prevItems.map((item) => {
        if (
          subItem &&
          item.id === selectedElement?.id &&
          item?.type === labelTypes.nutritional_fields
        ) {
          return {
            ...item,
            subItem: item.subItem.map((sub) =>
              sub.id === subItem?.id
                ? {
                    ...sub,
                    input: {
                      ...sub.input,
                      style: {
                        ...sub.input.style,
                        fontFamily: val,
                      },
                    },
                  }
                : sub
            ),
          };
        }

        if (item.id === selectedElement?.id) {
          return {
            ...item,
            input: {
              ...item.input,
              style: {
                ...item.input.style,
                fontFamily: val,
              },
            },
          };
        }

        return item;
      })
    );
  };

  const handleTextAlignment = (val) => {
    setItem((prevItems) =>
      prevItems.map((item) => {
        if (
          subItem &&
          item.id === selectedElement?.id &&
          item?.type === labelTypes.nutritional_fields
        ) {
          return {
            ...item,
            subItem: item.subItem.map((sub) =>
              sub.id === subItem?.id
                ? {
                    ...sub,
                    input: {
                      ...sub.input,
                      style: {
                        ...sub.input.style,
                        textAlign: val,
                      },
                    },
                  }
                : sub
            ),
          };
        }

        if (item.id === selectedElement?.id) {
          return {
            ...item,
            input: {
              ...item.input,
              style: {
                ...item.input.style,
                textAlign: val,
              },
            },
          };
        }

        return item;
      })
    );
  };

  const handleFontWeight = (e) => {
    setItem((prevItems) =>
      prevItems.map((item) => {
        if (
          subItem &&
          item.id === selectedElement?.id &&
          item?.type === labelTypes.nutritional_fields
        ) {
          return {
            ...item,
            subItem: item.subItem.map((sub) =>
              sub.id === subItem?.id
                ? {
                    ...sub,
                    input: {
                      ...sub.input,
                      style: {
                        ...sub.input.style,
                        fontWeight: e.target.checked ? "bold" : "normal",
                      },
                    },
                  }
                : sub
            ),
          };
        }

        if (item.id === selectedElement?.id) {
          return {
            ...item,
            input: {
              ...item.input,
              style: {
                ...item.input.style,
                fontWeight: e.target.checked ? "bold" : "normal",
              },
            },
          };
        }
        return item;
      })
    );
  };

  const handleShowLabel = (e) => {
    setItem((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedElement?.id
          ? {
              ...item,
              showLabel: e.target.checked ? true : false,
            }
          : item
      )
    );
  };

  return (
    <div className="feature_Editor features">
      <div className="featureTxt_Parent">
        <h2 className="fontSize24">Format Editor</h2>
      </div>
      <div className="feature_Editor_Child">
        {selectedElement?.type !== labelTypes.image && !preview && (
          <>
            {/* <div className="center d-flex align-items-center justify-content-between feature_Range">
              <label htmlFor="grid" className="snapgrid_Label fontSize14">
                Size To Fit:
              </label>
              <input type="checkbox" />
            </div> */}
            {selectedElement?.type !== labelTypes.nutritional_fields && (
              <div className="center d-flex align-items-center justify-content-between feature_Range">
                <label htmlFor="grid" className="snapgrid_Label fontSize14">
                  Show Label:
                </label>
                <input
                  type="checkbox"
                  onChange={handleShowLabel}
                  checked={
                    item?.find((i) => i.id == selectedElement.id)?.showLabel
                  }
                />
              </div>
            )}

            <div className="feature_Range">
              <label htmlFor="font-size" className="companyLabel">
                Font Size
              </label>
              <div className="d-flex justify-content-between align-items-center">
                <span>Min</span>
                <span>Max</span>
              </div>
              <input
                type="range"
                className="mt-3 editior"
                min={5}
                max={150}
                step={2}
                value={
                  parseFloat(
                    subItem &&
                      selectedElement?.type == labelTypes.nutritional_fields
                      ? item
                          ?.find((i) => i.id === selectedElement.id)
                          ?.subItem.find((sub) => sub.id === subItem.id)?.input
                          ?.style?.fontSize
                      : item?.find((i) => i.id === selectedElement.id)?.input
                          ?.style?.fontSize
                  ) || 10
                }
                onChange={(e) => handleChangeFontSize(e.target.value)}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <h3 className="range_Number fontSize14">5</h3>
                <h3 className="range_Number fontSize14">150</h3>
              </div>
            </div>
            <div className="feature_Range">
              <label htmlFor="Line Height" className="companyLabel">
                Line Height
              </label>
              <div className="d-flex justify-content-between align-items-center">
                <span>Min</span>
                <span>Max</span>
              </div>
              <input
                type="range"
                className="mt-3 editior"
                min={5}
                max={50}
                step={1}
                value={
                  parseFloat(
                    subItem &&
                      selectedElement?.type === labelTypes.nutritional_fields
                      ? item
                          ?.find((i) => i.id === selectedElement.id)
                          ?.subItem.find((sub) => sub.id === subItem.id)?.input
                          ?.style?.lineHeight
                      : item?.find((i) => i.id === selectedElement.id)?.input
                          ?.style?.lineHeight
                  ) || 10
                }
                onChange={(e) => handleLineHeight(e.target.value)}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <h3 className="range_Number fontSize14">5</h3>
                <h3 className="range_Number fontSize14">50</h3>
              </div>
            </div>
            <div className="feature_Range">
              <label htmlFor="name" className="companyLabel">
                Font
              </label>
              <select
                name="state"
                id="state"
                className="state_Select"
                value={
                  subItem &&
                  selectedElement?.type === labelTypes.nutritional_fields
                    ? item
                        ?.find((i) => i.id === selectedElement.id)
                        ?.subItem.find((sub) => sub.id === subItem.id)?.input
                        ?.style?.fontFamily
                    : item?.find((i) => i.id === selectedElement.id)?.input
                        ?.style?.fontFamily
                }
                onChange={(e) => handleChangeFont(e.target.value)}
              >
                {defaultFonts?.map((i, index) => (
                  <option value={i} key={index}>
                    {i}
                  </option>
                ))}
              </select>
            </div>
            <div className="feature_Range">
              <h3 className="fontSize14 mb-2">Text Position</h3>
              <div className="check_btns">
                <div className="check_wrp action">
                  <label>
                    <input
                      type="radio"
                      value="left"
                      name="text-align"
                      checked={
                        subItem &&
                        selectedElement?.type === labelTypes.nutritional_fields
                          ? item
                              ?.find((i) => i.id === selectedElement.id)
                              ?.subItem.find((sub) => sub.id === subItem.id)
                              ?.input?.style?.textAlign === "left"
                          : item?.find((i) => i.id === selectedElement.id)
                              ?.input?.style?.textAlign === "left"
                      }
                      onChange={(e) => handleTextAlignment(e.target.value)}
                    />
                    <span>
                      <FaAlignLeft />
                    </span>
                  </label>
                </div>

                <div className="check_wrp action">
                  <label>
                    <input
                      type="radio"
                      value="center"
                      name="text-align"
                      checked={
                        subItem &&
                        selectedElement?.type === labelTypes.nutritional_fields
                          ? item
                              ?.find((i) => i.id === selectedElement.id)
                              ?.subItem.find((sub) => sub.id === subItem.id)
                              ?.input?.style?.textAlign === "center"
                          : item?.find((i) => i.id === selectedElement.id)
                              ?.input?.style?.textAlign === "center"
                      }
                      onChange={(e) => handleTextAlignment(e.target.value)}
                    />
                    <span>
                      <FaAlignCenter />
                    </span>
                  </label>
                </div>

                <div className="check_wrp action">
                  <label>
                    <input
                      type="radio"
                      value="right"
                      name="text-align"
                      checked={
                        subItem &&
                        selectedElement?.type === labelTypes.nutritional_fields
                          ? item
                              ?.find((i) => i.id === selectedElement.id)
                              ?.subItem.find((sub) => sub.id === subItem.id)
                              ?.input?.style?.textAlign === "right"
                          : item?.find((i) => i.id === selectedElement.id)
                              ?.input?.style?.textAlign === "right"
                      }
                      onChange={(e) => handleTextAlignment(e.target.value)}
                    />
                    <span>
                      <FaAlignRight />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="feature_Range d-flex justify-content-between align-items-center">
              <h3 className="fontSize14">Bold Text</h3>
              <div className="check_btns">
                <div className="check_wrp action">
                  <label>
                    <input
                      type="checkbox"
                      value="bold"
                      name="text-align"
                      checked={
                        subItem &&
                        selectedElement?.type === labelTypes.nutritional_fields
                          ? item
                              ?.find((i) => i.id === selectedElement.id)
                              ?.subItem.find((sub) => sub.id === subItem.id)
                              ?.input?.style?.fontWeight === "bold"
                          : item?.find((i) => i.id === selectedElement.id)
                              ?.input?.style?.fontWeight === "bold"
                      }
                      onChange={handleFontWeight}
                    />
                    <span>B</span>
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="feature_Range">
              <h3 className="fontSize14 mb-2">Vertical Alignment</h3>
              <div className="text_Align_Container">
                <p className="verticle_Align">
                  <FaAngleDown />
                </p>
                <p className="verticle_Align active">
                  <MdOutlineHorizontalRule />
                </p>
                <p className="verticle_Align">
                  <FaAngleUp />
                </p>
              </div>
              <div className="mt-3">
                <label htmlFor="name" className="companyLabel">
                  Font
                </label>
                <select name="state" id="state" className="state_Select">
                  <option value="Haryana">Center</option>
                  <option value="Haryana">Left</option>
                  <option value="Punjab">Right</option>
                </select>
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default FormatEditor;
