import chroma from "chroma-js";
import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const DropDown = ({
  onChange,
  value,
  onBlur,
  defaultOptions,
  loadOptions,
  placeholder,
  id,
  depends,
  components,
  isClearable,
  name,
  className,
  isMulti,
  isDisabled,
}) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "15px",
      padding: "10px",
      backgroundColor: "#f1f9f0",
      boxShadow: "none",
      outline: "none",
      color: "#5D6163",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma("#42B03A");
      let str = "#42B03A";
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? str
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? str
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({ ...styles, color: "#000" }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
      maxHeight: "200px",
      overflowY: "auto",
      scrollbarWidth: "thin", // Firefox
      scrollbarColor: "#42B03A #f1f9f0", // Firefox
      // Add WebKit scrollbar styles
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#42B03A",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f9f0",
      },
    }),
  };

  return (
    <AsyncPaginate
      name={name}
      className={className}
      loadOptions={loadOptions}
      components={components}
      maxMenuHeight={150}
      onChange={onChange}
      defaultOptions={defaultOptions || false}
      placeholder={placeholder}
      styles={customStyles}
      isMulti={isMulti || false}
      debounceTimeout={500}
      additional={{
        page: 1,
      }}
      key={depends}
      id={id}
      isClearable={isClearable || false}
      value={value}
      isDisabled={isDisabled || false}
      onBlur={onBlur}
    />
  );
};

export default DropDown;
