import React from "react";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const NutritionTable = ({
  element,
  handleRemoveObject,
  setSelectedElement,
  setItem,
  preview,
  subItem,
  setSubItem,
}) => {
  const handleDeleteSubItem = (id) => {
    setItem((prev) =>
      prev.map((el) =>
        el.id === element.id
          ? { ...el, subItem: el.subItem.filter((i) => i.id !== id) }
          : el
      )
    );
  };

  return (
    <div className="NutritionalTable h-100 d-flex flex-column position-relative">
      <div
        style={{
          height:
            element.styles.height + (element?.subItem?.length * 20 || 0) + "px",
          width: element.styles.width,
          padding: "10px",
        }}
      >
        <h5
          className="font20 text-center pb-1"
          {...element.input}
          onClick={() => {
            setSelectedElement(element);
            setSubItem("");
          }}
          role="button"
        >
          Nutritional Fields
        </h5>
        <div className="lowerTable mt-1 ">
          {element.subItem?.map((item, index) => {
            return (
              <div
                className="d-flex justify-content-between position-relative"
                key={index}
                onClick={() => {
                  setSubItem(item);
                  setSelectedElement(element);
                }}
              >
                <p {...item.input}>{item?.labelText}</p>
                <p {...item.input}>{item?.value}</p>
                {subItem?.id == item?.id && !preview && (
                  <span
                    className="cross-icon-label ms-1"
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSubItem(item?.id);
                    }}
                  >
                    <CrossIcon size={7} />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {!preview && (
        <span
          className="cross-icon-label ms-1"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveObject(element.id);
            setSelectedElement("");
            setSubItem("");
          }}
        >
          <CrossIcon size={13} />
        </span>
      )}
    </div>
  );
};

export default NutritionTable;
