const defaultFonts = [
  "Times New Roman",
  "Arial",
  "Courier New",
  "MS Gothic",
  "SimSun",
];

const labelTypes = {
  image: "image",
  text_fields: "text_fields",
  start_date_field: "start_date_field",
  end_date_field: "end_date_field",
  transitional_date: "transitional_date",
  nutritional_fields: "nutritional_fields",
  qr_code: "qr",
  bar_code: "bar",
};
const days = [
  { label: 'M', style: { backgroundColor: '#0078d4', color: 'white' } },
  { label: 'Tu', style: { backgroundColor: '#f5dd01', color: 'black' } },
  { label: 'W', style: { backgroundColor: '#d32f2f', color: 'white' } },
  { label: 'Th', style: { backgroundColor: '#795548', color: 'white' } },
  { label: 'F', style: { backgroundColor: '#4caf50', color: 'white' } },
  { label: 'Sa', style: { backgroundColor: '#f57c00', color: 'white' } },
  { label: 'Su', style: { backgroundColor: '#333333', color: 'white' } },
];

const dateIncludes = [
  { key: "date", label: "Show date" },
  { key: "time", label: "Show time" },
  { key: "day", label: "Show day of week" },
];

const dateFormat = {
  monthFirst: "mm/dd/yyyy h:mm a",
  dateFirst: "dd/mm/yyyy h:mm a",
};

const positionAttrbutes = {
  position: {
    x: 0,
    y: 0,
  },
  styles: {
    width: 100,
    height: 10,
  },
  input: {
    style: {
      fontSize: "10px",
      fontFamily: defaultFonts?.[0],
      textAlign: "left",
      fontWeight: "normal",
      lineHeight: "20px",
    },
  },
  minWidth: 50,
  minHeigth: 30,
};

const subItemStyle = {
  input: {
    style: {
      fontSize: "6px",
      fontFamily: defaultFonts?.[0],
      textAlign: "left",
      fontWeight: "normal",
    },
  },
};

const textFields = [
  {
    label: "Item Name (English)",
    labelText: "Name:",
    showLabel: false,
    fieldType: "text",
    key: "en_name",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Enter Item Name Here(English)",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Item Name (Spanish)",
    labelText: "Name:",
    showLabel: false,
    fieldType: "text",
    key: "es_name",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Enter Item Name Here (Spanish)",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 115,
      height: 25,
    },
  },
  {
    label: "Price",
    labelText: "Price:",
    showLabel: false,
    fieldType: "text",
    key: "additionalInfo",
    childKey: "sellingPrice",
    ...positionAttrbutes,
    placeholder: "Enter Item Price Here",
    value: "$",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Ingredients",
    labelText: "Ingredients:",
    showLabel: true,
    fieldType: "text",
    key: "nutritionalInfo",
    childKey: "ingredients",
    ...positionAttrbutes,
    placeholder: "Enter Item Ingredients Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 140,
      height: 25,
    },
  },
  {
    label: "Allergens",
    key: "nutritionalInfo",
    labelText: "Allergens:",
    showLabel: true,
    fieldType: "text",
    childKey: "allergens",
    ...positionAttrbutes,
    placeholder: "Enter Item Allergens Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "SKU",
    labelText: "SKU:",
    showLabel: true,
    fieldType: "text",
    key: "additionalInfo",
    childKey: "sku",
    ...positionAttrbutes,
    placeholder: "Enter Item SKU Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Address",
    key: "address",
    childKey: false,
    labelText: "Address:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Item Address Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Employee Name",
    key: "employee",
    childKey: false,
    labelText: "Employee:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Item Employee Name Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Prepped By",
    key: "en_name",
    childKey: false,
    labelText: "Prepped By:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Prepped By Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Username",
    key: "prepped",
    childKey: false,
    labelText: "Username:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Item Username Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Description",
    key: "description",
    childKey: false,
    labelText: "Description:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Item Description Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Net Weight",
    key: "additionalInfo",
    childKey: "netWeight",
    labelText: "Net Weight:",
    showLabel: true,
    fieldType: "text",
    ...positionAttrbutes,
    placeholder: "Enter Item Net Weight Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
  {
    label: "Custom Term",
    key: "additionalInfo",
    labelText: "Custom Term:",
    showLabel: false,
    fieldType: "text",
    childKey: "customFieldValue",
    ...positionAttrbutes,
    placeholder: "Enter Item Custom Term Here",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
  },
];

const startDateFields = [
  {
    label: "Made",
    labelText: "Made:",
    showLabel: true,
    key: "made",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Made",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Pull",
    labelText: "Pull:",
    showLabel: true,
    key: "pull",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Pull",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Received",
    labelText: "Received:",
    showLabel: true,
    key: "recieved",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Received",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Received On",
    labelText: "Received On:",
    showLabel: true,
    key: "received_on",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Received On",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Make",
    labelText: "Make:",
    showLabel: true,
    key: "make",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Make",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Prep",
    labelText: "Prep:",
    showLabel: true,
    key: "prep",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Prep",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Day, Date, Time",
    labelText: "Day, Date, Time:",
    showLabel: true,
    key: "day_date_time",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Day, Date, Time",
    format: dateFormat.monthFirst,
    dateIncludes: {
      date: true,
      day: true,
      time: true,
    },
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Kill Date",
    labelText: "Kill Date:",
    showLabel: true,
    key: "kill_date",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Kill Date",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Picked On",
    labelText: "Picked On:",
    showLabel: true,
    key: "picked_on",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Picked On",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Manufactured On",
    labelText: "Manufactured On:",
    showLabel: true,
    key: "manufacture_on",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Manufactured On",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "MFG",
    labelText: "MFG:",
    showLabel: true,
    key: "mfg",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "MFG",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Opened",
    labelText: "Opened:",
    showLabel: true,
    key: "opened",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Opened",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
];

const transitionalDateFields = [
  {
    label: "Ready",
    labelText: "Ready:",
    showLabel: true,
    key: "ready",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Ready",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Thaw",
    labelText: "Thaw:",
    showLabel: true,
    key: "thaw",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Temper",
    labelText: "Temper:",
    showLabel: true,
    key: "temper",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Temper",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Pull",
    labelText: "Pull:",
    showLabel: true,
    key: "pull",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Pull",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Marinate",
    labelText: "Marinate:",
    showLabel: true,
    key: "marinate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Marinate",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Thaw Through",
    labelText: "Thaw Through:",
    showLabel: true,
    key: "thawThrough",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw Through",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Marinate Until",
    labelText: "Marinate Until:",
    showLabel: true,
    key: "marinateUntil",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Marinate Until",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Thaw Until",
    labelText: "Thaw Until:",
    showLabel: true,
    key: "thawUntil",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw Until",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
];

const endDateFields = [
  {
    label: "Must Use By",
    labelText: "Must Use By:",
    showLabel: true,
    key: "mustUseBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Must Use By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Use By",
    labelText: "Use By:",
    showLabel: true,
    key: "useBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Use By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Discard",
    labelText: "Discard:",
    showLabel: true,
    key: "discard",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Discard",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Expires",
    labelText: "Expires:",
    showLabel: true,
    key: "expires",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Expires",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Best By",
    labelText: "Best By:",
    showLabel: true,
    key: "bestBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Best By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Freshest By",
    labelText: "Freshest By:",
    showLabel: true,
    key: "freshestBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Freshest By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Freshest Date",
    labelText: "Freshest Date:",
    showLabel: true,
    key: "freshestDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Freshest Date",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Sell By",
    labelText: "Sell By:",
    showLabel: true,
    key: "sellBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Sell By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Best Before",
    labelText: "Best Before:",
    showLabel: true,
    key: "bestBefore",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Best Before",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Must Sell By",
    labelText: " Must Sell By:",
    showLabel: true,
    key: "mustSellBy",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Must Sell By",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Expire",
    labelText: "Expire:",
    showLabel: true,
    key: "expire",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Expire",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
  {
    label: "Exp",
    labelText: "Exp:",
    showLabel: true,
    key: "exp",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Exp",
    format: dateFormat.monthFirst,
    dateIncludes: {},
    value: "",
    position: {
      x: 0,
      y: 90,
    },
    styles: {
      width: 170,
      height: 25,
    },
  },
];
let servingSizeKey = "servingSize";
let caloriesKey = "calories";
const nutritionFields = [
  {
    label: "Serving size",
    labelText: "Serving size:",
    showLabel: false,
    fieldType: "text",
    key: servingSizeKey,
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Serving size",
    value: "2/3 cup (55g)",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 94,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Calories",
    labelText: "Calories:",
    showLabel: false,
    fieldType: "text",
    key: caloriesKey,
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Calories",
    value: "100 Cal",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Total Fat(g)",
    labelText: "Total Fat(g):",
    showLabel: false,
    fieldType: "text",
    key: "total_fat",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Total Fat(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Saturated Fat(g)",
    labelText: "Saturated Fat(g):",
    showLabel: false,
    fieldType: "text",
    key: "saturated_fat",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Saturated Fat(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Trans Fat(g)",
    labelText: "Trans Fat(g):",
    showLabel: false,
    fieldType: "text",
    key: "trans_fat",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Trans Fat(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Cholesterol(mg)",
    labelText: "Cholesterol(mg):",
    showLabel: false,
    fieldType: "text",
    key: "cholesterol",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Cholesterol(mg)",
    value: "10 mg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
    ...subItemStyle,
  },
  {
    label: "Carbohydrates(g)",
    labelText: "Carbohydrates(g):",
    showLabel: false,
    fieldType: "text",
    key: "carbohydrates",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Carbohydrates(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Protein(g)",
    labelText: "Protein(g):",
    showLabel: false,
    fieldType: "text",
    key: "protein",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Protein(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Dietary Fiber(g)",
    labelText: "Dietary Fiber(g):",
    showLabel: false,
    fieldType: "text",
    key: "protein",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Dietary Fiber(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Total Sugar(g)",
    labelText: "Total Sugar(g):",
    showLabel: false,
    fieldType: "text",
    key: "totalSugar",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Total Sugar(g)",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Sodium(mg)",
    labelText: "Sodium(mg):",
    showLabel: false,
    fieldType: "text",
    key: "sodium",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Sodium(mg)",
    value: "10 mg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Servings Per Container",
    labelText: "Servings Per Container:",
    showLabel: false,
    fieldType: "text",
    key: "serving_per_container",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Servings Per Container",
    value: "10",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Added Sugar",
    labelText: "Added Sugar:",
    showLabel: false,
    fieldType: "text",
    key: "added_sugar",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Added Sugar",
    value: "10 g",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Calories From Fat",
    labelText: "Calories From Fat:",
    showLabel: false,
    fieldType: "text",
    key: "calories_from_fat",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Calories From Fat",
    value: "10 Cal",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Vitamin D",
    labelText: "Vitamin D:",
    showLabel: false,
    fieldType: "text",
    key: "vitaminD",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Vitamin D",
    value: "10 mcg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Vitamin D%",
    labelText: "Vitamin D%:",
    showLabel: false,
    fieldType: "text",
    key: "vitaminD%",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Vitamin D%",
    value: "10 mcg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Iron",
    labelText: "Iron:",
    showLabel: false,
    fieldType: "text",
    key: "iron",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Iron",
    value: "10 mcg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Potassium",
    labelText: "Potassium:",
    showLabel: false,
    fieldType: "text",
    key: "potassium",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Potassium",
    value: "10 mcg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Potassium%",
    labelText: "Potassium%:",
    showLabel: false,
    fieldType: "text",
    key: "potassium%",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Potassium%",
    value: "10 mcg",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
];

const othersFields = [
  {
    label: "Barcode – Fixed",
    key: "bar_code",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/bar.png",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 60,
      height: 40,
    },
    type: labelTypes.bar_code,
    minWidth: 40,
    minHeight: 30,
  },
  {
    label: "Barcode – Variable",
    key: "bar_code_variable",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/bar.png",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 60,
      height: 40,
    },
    type: labelTypes.bar_code,
    minWidth: 40,
    minHeight: 30,
  },
  {
    label: "QR Code",
    key: "qr_code",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/qr.png",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 60,
      height: 60,
    },
    type: labelTypes.qr_code,
    minWidth: 30,
    minHeight: 30,
  },
  {
    label: "Print Sequence",
    labelText: "Print Sequence:",
    showLabel: false,
    fieldType: "text",
    key: "print_sequence",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Print Sequence",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
  {
    label: "Waste Prepped Quantity",
    labelText: "Waste Prepped Quantity:",
    showLabel: false,
    fieldType: "text",
    key: "waste_prepped_quantity",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Waste Prepped Quantity",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
  {
    label: "Prepared Temperature",
    labelText: "Prepared Temperature:",
    showLabel: false,
    fieldType: "text",
    key: "prepared_temperature",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Prepared Temperature",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
];

export {
  caloriesKey,
  dateFormat,
  dateIncludes,
  defaultFonts,
  endDateFields,
  labelTypes,
  nutritionFields,
  servingSizeKey,
  startDateFields,
  textFields,
  transitionalDateFields,
  othersFields,
  days
};
