import React, { useState } from "react";
import { FaCalendar } from "react-icons/fa6";
import { CrossIcon } from "../../../SvgIcons/allIcons";
import DateFormatModel from "./DateFormatModel";
import moment from "moment/moment";
import { dateFormat } from "../../../utils/labelConstants";

export default function DateInput({
  element,
  preview,
  handleRemoveObject,
  setSelectedElement,
  setItem,
}) {
  const [show, setShow] = useState(false);

  const handleValue = () => {
    if (!element?.value) return '';
  
    const date = new Date(element.value);
    const format = element.format === dateFormat.monthFirst ? "MM/DD/YYYY" : "DD/MM/YYYY";
    const formattedDate = moment(date).format(format);
    const formattedTime = moment(date).format("hh:mm A");
    const formattedDay = moment(date).format("ddd");
  
    const { date: includeDate, time: includeTime, day: includeDay } = element?.dateIncludes || {};
  
    if (includeDate && includeTime && includeDay) {
      return `${formattedDay} ${formattedDate} at ${formattedTime}`;
    } else if (includeDate && includeTime) {
      return `${formattedDate} at ${formattedTime}`;
    } else if (includeDate && includeDay) {
      return `${formattedDay} ${formattedDate}`;
    } else if (includeTime && includeDay) {
      return `${formattedDay} at ${formattedTime}`;
    } else if (includeDate) {
      return formattedDate;
    } else if (includeTime) {
      return formattedTime;
    } else {
      return formattedDay;
    }
  };

  return (
    <div
      className="position-relative"
      onClick={() => setSelectedElement(element)}
    >
      {preview ? (
        <div className="label-input-preview d-flex gap-1">
          {element.showLabel && (
            <label className="labelMain" {...element.input}>
              {element.labelText}
            </label>
          )}
          <p className="commonInput" {...element.input}>
            {handleValue() || ""}
          </p>
        </div>
      ) : (
        <div className="label-input d-flex gap-1">
          {element.showLabel && (
            <label className="labelMain" {...element.input}>
              {element.labelText}
            </label>
          )}
          <p className="commonInput" {...element.input}>
            {element.value ? handleValue() : element.placeholder}
          </p>
          <span
            className="cross-icon-label ms-1"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveObject(element.id);
              setSelectedElement("");
            }}
          >
            <CrossIcon size={13} />
          </span>
          <span
            className="calendar-icon-label ms-1"
            role="button"
            onClick={() => {
              setShow(true);
            }}
          >
            <FaCalendar size={10} />
          </span>
        </div>
      )}
      {show && (
        <DateFormatModel
          show={show}
          handleClose={() => setShow(false)}
          setItem={setItem}
          element={element}
        />
      )}
    </div>
  );
}
