import React from "react";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const TextField = ({
  element,
  preview,
  handleChange,
  handleRemoveObject,
  setSelectedElement,
}) => {
  let inputProps = {
    onChange: (e) => handleChange(e.target.value, element.id),
    value: element.value || "",
  };

  return (
    <div
      className="position-relative"
      onClick={() => setSelectedElement(element)}
    >
      {preview ? (
        <div className="label-input-preview d-flex gap-1">
          {element.showLabel && (
            <label className="labelMain" {...element.input}>
              {element.labelText}
            </label>
          )}
          <p className="commonInput" {...element.input}>
            {element.value || ""}
          </p>
        </div>
      ) : (
        <div className="label-input">
          <span className="d-flex gap-1">
            {element.showLabel && (
              <p className="labelMain" {...element.input}>
                {element.labelText}
              </p>
            )}
            <input
              type={element.fieldType || "text"}
              className="commonInput"
              name="taskDate"
              placeholder={element.placeholder || ""}
              {...inputProps}
              {...element.input}
            />
            <span
              className="cross-icon-label ms-1"
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveObject(element.id);
                setSelectedElement("");
              }}
            >
              <CrossIcon size={13} />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default TextField;
