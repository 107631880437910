import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { useQuery } from "@tanstack/react-query";
import { getDataListbyId } from "../../services/services";
import { useParams } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import moment from "moment";
import ImageModal from "../../components/ImageModal";

const CompleteTaskdetail = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const { data } = useQuery({
    queryKey: ["task-details", id],
    queryFn: async () => {
      const resp =
        !!id && (await getDataListbyId(id, details?.companyUID, true, true));
      let data = resp?.data?.data;
      return data ?? {};
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack>{t("task.taskDetails")}</NavigateBack>
          </Col>
          {/* <Col className="text-end" xl={6}>
            {true ? (
              <button className="userBtn btn-theme">
                {t("task.markAsCompleted")}
              </button>
            ) : (
              <button className="userBtn btn-theme">
                {t("task.completed")}
              </button>
            )}
          </Col> */}
        </Row>
        <div className="tableOuter">
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2"> {t("task.basicInfo")}</p>
            <Col className="infoColumn" xl={5}>
              <p className="mt-2">
                {t("task.taskListName")}:{" "}
                <span className="colorDark">{data?.name}</span>
              </p>
              <p className="mt-2">
                {t("task.employeeName")}:{" "}
                <span className="colorDark">
                  {data?.customerDetails?.userInfo?.name}
                </span>
              </p>
              <p className="mt-2">
                {t("task.startTime")}:{" "}
                <span className="colorDark">
                  {data?.details?.startTime ? data?.details?.startTime : "N/A"}
                </span>
              </p>
              <p className="mt-2">
                {t("task.taskLocation")}:{" "}
                <span className="colorDark">
                  {data?.locations?.map((i) => i.name)?.join(", ")}
                </span>
              </p>
              <p className="mt-2">
                {t("task.pictureRequired")}:{" "}
                <span className="colorDark">
                  {data?.details?.pictureRequired
                    ? t("task.true")
                    : t("task.false")}
                </span>
              </p>
            </Col>

            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
              <p className="mt-2">
                {t("task.taskName")}:{" "}
                <span className="colorDark">{data?.details?.taskName}</span>
              </p>
              <p className="mt-2">
                {t("task.createdOn")}:{" "}
                <span className="colorDark">
                  {moment(data?.createdAt).format("lll")}
                </span>
              </p>
              <p className="mt-2">
                {t("task.taskDate")}:{" "}
                <span className="colorDark">
                  {moment(data?.details?.taskDate).format("ll")}
                </span>
              </p>
              <p className="mt-2">
                {t("task.endTime")}:{" "}
                <span className="colorDark">
                  {data?.details?.endTime ? data?.details?.endTime : "N/A"}
                </span>
              </p>
              {/* <p>
                {t("task.comment")}: <span className="colorDark">20</span>
              </p> */}
            </Col>
            {data?.details?.image && (
              <Col md={12} className="d-flex align-items-start mt-3">
                <p> {t("task.image")}: </p>
                <span className="colorDark ms-2">
                  <img
                    src={data?.details?.image}
                    width={400}
                    role="button"
                    onClick={() => setShow(data?.details?.image)}
                  />
                </span>
              </Col>
            )}
          </Row>
          {false && (
            <Row className="mb-3">
              <p className="colorDark font-18 mb-2">{t("task.ReportInfo")}</p>
              <Col className="infoColumn" xl={5}>
                <p>
                  {t("task.whenWasItCompleted")}:{" "}
                  <span className="colorDark">13/06/2024</span>
                </p>
                <p>
                  {t("task.howLongDidItTake")}::{" "}
                  <span className="colorDark">2 Weeks</span>
                </p>
              </Col>

              <Col className="CenterLine" xl={2}></Col>
              <Col className="infoColumn" xl={5}>
                <p>
                  {t("task.whoCompletedIt")}:{" "}
                  <span className="colorDark">Wade Warren</span>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ImageModal show={show} setShow={setShow} />
    </div>
  );
};

export default CompleteTaskdetail;
