import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { CrossIcon } from "../../../SvgIcons/allIcons";
import { dateFormat, dateIncludes } from "../../../utils/labelConstants";

const DateFormatModel = ({ show, handleClose, setItem, element }) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: {
      date: element?.value ? new Date(element?.value) : new Date(),
      dateIncludes: {
        date: element?.dateIncludes?.date ? element?.dateIncludes?.date : true,
        day: element?.dateIncludes?.day ? element?.dateIncludes?.day : false,
        time: element?.dateIncludes?.time ? element?.dateIncludes?.time : false,
      },
      format: element?.format ? element?.format : dateFormat?.monthFirst,
    },
    validationSchema: yup.object().shape({
      dateIncludes: yup
        .object()
        .shape({
          date: yup.boolean(),
          day: yup.boolean(),
          time: yup.boolean(),
        })
        .test("at-least-one-true", "At least one value required", (value) => {
          return value && Object.values(value).some((v) => v === true);
        }),
    }),
    onSubmit: async (values) => {
      await setItem((prevItems) =>
        prevItems?.map((item) =>
          item?.id === element?.id
            ? {
                ...item,
                dateIncludes: values?.dateIncludes,
                value: values?.date,
                format: values?.format,
              }
            : item
        )
      );
      handleClose();
      handleReset();
    },
  });

  return (
    <Modal show={!!show} centered dialogClassName="custom-modal">
      <Modal.Header className="deleteUser_ModalHeader" closeButton={false}>
        <Modal.Title>Choose Date Format</Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <div className="dateFormat mb-3">
              <h5 className="mb-2">
                Date Must Includes : <span className="text-danger">*</span>
              </h5>
              <Row>
                {dateIncludes &&
                  dateIncludes.map((formatData, index) => {
                    return (
                      <Col md={4} key={`date_${index}`}>
                        <div className="customer_Access mt-0">
                          <div className="access_Child">
                            <div className="form-group">
                              <input
                                type="checkbox"
                                id={formatData.key}
                                name={`dateIncludes.${formatData.key}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values?.dateIncludes[formatData.key]}
                              />
                              <label htmlFor={formatData.key}>
                                <span className="fontSize14 ms-2">
                                  {formatData.label}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                <small className="text-danger ms-1">
                  {touched?.dateIncludes && errors?.dateIncludes}
                </small>
              </Row>
            </div>
          </Col>
          <Col md={12}>
            <div className="dateFormat mb-4">
              <h5 className="mb-2">Date :</h5>

              <DatePicker
                selected={values.date}
                onChange={(date) => setFieldValue("date", date)}
                showTimeSelect
                className="commonInput"
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat={values?.format}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="dateFormat mb-3">
              <div>
                <h5 className="mb-3">Date Format :</h5>
              </div>
              <Row>
                <div className="select_Role d-flex align-items-center">
                  <Col md={6}>
                    <input
                      type="radio"
                      id={dateFormat?.monthFirst}
                      name="format"
                      value={dateFormat?.monthFirst}
                      checked={values?.format == dateFormat?.monthFirst}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor={dateFormat?.monthFirst}
                      className="position_Label free"
                    >
                      MM/DD/YYYY
                    </label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="radio"
                      id={dateFormat?.dateFirst}
                      name="format"
                      value={dateFormat?.dateFirst}
                      checked={values?.format == dateFormat?.dateFirst}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor={dateFormat?.dateFirst}
                      className="position_Label free"
                    >
                      DD/MM/YYYY
                    </label>
                  </Col>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="deleteUser_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme"
          type="button"
          onClick={() => handleSubmit()}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateFormatModel;
