import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import {
  addDataList,
  getCompanyLocation,
  getDataListbyId,
  getItems,
  updateDataList,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import i18next from "i18next";

const AddnewPreplist = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const details = useDetails();
  const { t } = useTranslation();
  const isSlider = useSlider();
  const navigate = useNavigate();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      productionName: "",
      items: "",
      qtyLabel: "",
      labelProfile: "",
      prepQty: "",
      locations: [],
      assignAll: false,
    },
    validationSchema: yup.object().shape({
      productionName: yup
        .string()
        .required()
        .label(t("prepList.productionName")),
      items: yup.object().shape({
        value: yup.string().required().label(t("prepList.items")),
      }),
      qtyLabel: yup
        .number()
        .required()
        .typeError(t("errorMsg.invalidNumber"))
        .positive()
        .min(1)
        .label(t("prepList.qunatityLabel")),
      // labelProfile: yup.string().required().label(t("prepList.labelProfile")),
      // prepQty: yup
      //   .number()
      //   .required()
      //   .typeError(t("errorMsg.invalidNumber"))
      //   .positive()
      //   .min(1)
      //   .label(t("prepList.preppedQty")),
      // locations: yup.array().min(1).label(t("prepList.prepLocation")),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.productionName,
        type: constant.DATA_LIST.PREP_LIST,
        itemId: values?.items?.value,
        assignAll: values?.assignAll,
        details: {
          labelQuantity: values?.qtyLabel,
          prepQuantity: values?.prepQty,
          labelProfile: values?.labelProfile,
        },
      };
      if (!values?.assignAll) {
        body.locations = values?.locations;
      }
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updateDataList(id, body, details?.companyUID)
        : addDataList(body, details?.companyUID),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../prep-list");
    },
  });

  useQuery({
    queryKey: ["receiving-details", id],
    queryFn: async () => {
      const resp = !!id && (await getDataListbyId(id, details?.companyUID));
      let data = resp?.data?.data;
      if (data) {
        setValues({
          ...values,
          productionName: data?.name,
          items: {
            value: data?.itemDetails?._id,
            label:
              data?.itemDetails?.en_name,
            locations: data?.itemDetails?.locations,
            assignAll: data?.itemDetails?.assignAll,
          },
          qtyLabel: data?.details?.labelQuantity,
          labelProfile: data?.details?.labelProfile,
          // prepQty: data?.details?.prepQuantity,
          locations: data?.locations,
          assignAll: data?.assignAll,
        });
      }
      return true;
    },
  });

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp = await getCompanyLocation(
      details?.companyUID,
      page,
      constant.PER_PAGE_TEN,
      search
    );

    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.locations?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadItems = async (search, loadedOptions, { page }) => {
    let resp = await getItems(
      page,
      constant.PER_PAGE_TEN,
      details?.companyUID,
      false
    );

    let array = (await resp?.data?.data?.items) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: i18next?.language == "es" ? item?.es_name : item?.en_name,
        locations: item?.locations,
        assignAll: item?.assignAll,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <NavigateBack>
              {!!id ? t("prepList.editPrepList") : t("prepList.addNewPrepList")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="productionName" className="companyLabel">
                  {t("prepList.productionName")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="productionName"
                  value={values?.productionName}
                  name="productionName"
                  className="commonInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("prepList.enterProductionName")}
                />
                <small className="text-danger ms-1">
                  {touched?.productionName && errors?.productionName}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="items" className="companyLabel">
                  {t("prepList.items")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("prepList.enterItem")}
                  id="items"
                  loadOptions={loadItems}
                  isClearable={false}
                  value={values?.items}
                  onChange={(e) => {
                    setFieldValue("items", e);
                    setFieldValue("locations", e?.locations);
                    setFieldValue("assignAll", e?.assignAll);
                  }}
                  onBlur={() => setFieldTouched("items", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.items && errors?.items?.value}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="qtyLabel" className="companyLabel">
                  {t("prepList.qunatityLabel")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="qtyLabel"
                  value={values?.qtyLabel}
                  name="qtyLabel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("prepList.enterQtyLabel")}
                />
                <small className="text-danger ms-1">
                  {touched?.qtyLabel && errors?.qtyLabel}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="labelProfile" className="companyLabel">
                  {t("prepList.labelProfile")}{" "}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  id="labelProfile"
                  name="labelProfile"
                  value={values?.labelProfile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("prepList.enterLabelProfile")}
                />{" "}
                <small className="text-danger ms-1">
                  {touched?.labelProfile && errors?.labelProfile}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col md={6}>
              <div className="position-relative mt-3">
                <label htmlFor="prepQty" className="companyLabel">
                  {t("prepList.preppedQtyPerLabel")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="prepQty"
                  className="commonInput"
                  value={values?.prepQty}
                  name="prepQty"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("prepList.enterPreppedQty")}
                />
                <small className="text-danger ms-1">
                  {touched?.prepQty && errors?.prepQty}
                </small>
              </div>
            </Col> */}
            {/* <Col md={6}>
              <div className="mt-3">
                <label htmlFor="locations" className="companyLabel">
                  {t("prepList.prepLocation")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("prepList.enterPrepLocaiton")}
                  id="locations"
                  loadOptions={loadLocations}
                  isClearable={false}
                  isMulti={true}
                  value={values?.locations}
                  onChange={(e) => setFieldValue("locations", e)}
                  onBlur={() => setFieldTouched("locations", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.locations && errors?.locations}
                </small>
              </div>
            </Col>
            <Col md={12}>
              <div className="customer_Access">
                <div className="access_Child">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="assignAll"
                      name="assignAll"
                      value={values?.assignAll}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values?.assignAll == true}
                    />
                    <label htmlFor="assignAll">
                      <span className="fontSize14 ms-2">
                        {t("prepList.applyToAllCurrentAndFutreLocations")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </Col> */}

            <Row>
              <Col className="mt-4" xl={12}>
                <button
                  className="userBtn btn-theme m-0"
                  type="button"
                  onClick={handleSubmit}
                >
                  {!!id ? t("globals.saveChanges") : t("globals.add")}
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddnewPreplist;
