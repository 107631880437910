import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {} from "../../SvgIcons/allIcons";
import NavigateBack from "../../components/NavigateBack";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { getItemDetails } from "../../services/services";
import Sidebar from "../sidebar/Sidebar";
import Barcode from "react-barcode";

const ItemDetails = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ["item-details", id],
    queryFn: async () => {
      const resp = await getItemDetails(id, details?.companyUID);
      return resp?.data?.data;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack>{t("item.itemDetails")}</NavigateBack>
          </Col>
          {/* <Col className="text-end mb-2" xl={6}>
            <button className="userBtn btn-theme">{t("item.completed")}</button>
          </Col> */}
        </Row>
        <div className="tableOuter">
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">{t("item.basicInfo")}</p>
            <Col className="infoColumn" xl={5}>
              <p className="mb-2">
                {t("item.itemName")}:{" "}
                <span className="colorDark">{data?.en_name}</span>
              </p>
              <p className="mb-2">
                {t("item.category")}:{" "}
                <span className="colorDark">
                  {i18next?.language == "es"
                    ? data?.categoryDetails?.es_name
                    : data?.categoryDetails?.en_name}
                </span>
              </p>
              <p className="mb-3">
                {t("item.expirationTime")}:{" "}
                <span className="colorDark greenBox">
                  {`${data?.expirationTime?.days}d:${data?.expirationTime?.hours}h:${data?.expirationTime?.mins}m`}
                </span>
              </p>
              {data?.eodExpiration && (
                <p className="mb-3">
                  {t("item.expiration")}:{" "}
                  <span className="colorDark greenBox">
                    {t("item.endOfDay")}
                  </span>
                </p>
              )}

              <p className="mb-3">
                {t("item.thawTime")}:{" "}
                <span className="colorDark greenBox">{`${data?.thawExpiration?.days}d:${data?.thawExpiration?.hours}h:${data?.thawExpiration?.mins}m`}</span>
              </p>
              {data?.eodThrawExpiration && (
                <p className="mb-3">
                  {t("item.expiration")}:{" "}
                  <span className="colorDark greenBox">
                    {t("item.endOfDay")}
                  </span>
                </p>
              )}
            </Col>

            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
              <p className="mb-3">
                {t("item.itemNameSpanish")}:{" "}
                <span className="colorDark">
                  {data?.es_name ? data?.es_name : "N/A"}
                </span>
              </p>
              <p className="mb-3">
                {t("item.applyToLocation")}:{" "}
                <span className="colorDark">
                  {data?.locations?.map((i) => i.name)?.join(", ")}
                </span>
              </p>
            </Col>
          </Row>
          {data?.wasteTracking?.enabled && (
            <Row className="mb-3">
              <p className="colorDark font-18 mb-2">
                {t("item.productWasteTracking")}
              </p>
              <Col className="infoColumn" xl={5}>
                <p className="mb-3">
                  {t("item.unitOfMeasure")}:{" "}
                  <span className="colorDark">
                    {data?.wasteTracking?.measureUnit}
                  </span>
                </p>
                <p className="mb-3">
                  {t("item.costOfGoods")}:{" "}
                  <span className="colorDark">
                    ${data?.wasteTracking?.costOfGoods}
                  </span>
                </p>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">{t("item.additionalInfo")}</p>
            <Col className="infoColumn" xl={5}>
              <p className="mb-3">
                {t("item.sku")}:{" "}
                <span className="colorDark">{data?.additionalInfo?.sku}</span>
              </p>
              <p className="mb-3">
                {t("item.itemSellPrice")}:{" "}
                <span className="colorDark">
                  ${data?.additionalInfo?.sellingPrice}
                </span>
              </p>
              <p className="mb-3">
                {t("item.netWeight")}:{" "}
                <span className="colorDark">
                  {data?.additionalInfo?.netWeight}
                </span>
              </p>
              <p className="mb-3">
                {t("item.description")}:{" "}
                <span className="colorDark">
                  {data?.additionalInfo?.description}
                </span>
              </p>
              <p className="mb-3">
                {t("item.customField")}:{" "}
                <span className="colorDark">
                  {data?.additionalInfo?.customFieldValue
                    ? data?.additionalInfo?.customFieldValue
                    : "N/A"}
                </span>
              </p>
            </Col>
            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
              <p className="mb-3">
                {t("item.barCodeNumber")}:{" "}
                <span className="colorDark">
                  {data?.additionalInfo?.barCodeNumber
                    ? data?.additionalInfo?.barCodeNumber
                    : "NA"}
                </span>
              </p>
              <p>
                {t("item.barcode")}:{" "}
                <span>
                  {data?.additionalInfo?.barCodeImage ? (
                    <img
                      src={data?.additionalInfo?.barCodeImage}
                      className="img-fluid"
                      alt="Barcode"
                    />
                  ) : !!data?.additionalInfo?.barCodeNumber ? (
                    <Barcode value={data?.additionalInfo?.barCodeNumber} />
                  ) : (
                    "N/A"
                  )}
                </span>
              </p>
              <p className="mb-3">
                {t("item.qrCode")}:{" "}
                <span>
                  {data?.additionalInfo?.qrCodeImage ? (
                    <img
                      src={data?.additionalInfo?.qrCodeImage}
                      className="img-fluid"
                      alt="Barcode"
                    />
                  ) : (
                    "N/A"
                  )}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">
              {t("item.nutritionalInfo")}
            </p>
            <Col className="infoColumn" xl={5}>
              <p className="mb-3">
                {t("item.ingredients")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.ingredients
                    ? data?.nutritionalInfo?.ingredients
                    : "N/A"}
                </span>
              </p>
              <p className="mb-3">
                {t("item.servingSize")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.servingSize}
                </span>
              </p>
              <p className="mb-3">
                {t("item.calories")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.calories}
                </span>
              </p>
              <p className="mb-3">
                {t("item.saturatedFat")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.saturatedFat}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.caloriesFromFat")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.caloriesFromFat}
                </span>
              </p>
              <p className="mb-3">
                {t("item.carbohydrates")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.carbohydrates}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.dietaryFiber")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.dietaryFiber}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.addedSugar")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.addedSugar}%
                </span>
              </p>
              <p className="mb-3">
                {t("item.vitaminA")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.vitaminA}%
                </span>
              </p>
              <p className="mb-3">
                {t("item.vitaminD")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.vitaminD}%
                </span>
              </p>
              <p className="mb-3">
                {t("item.iron")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.iron}%
                </span>
              </p>
            </Col>
            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
              <p className="mb-3">
                {t("item.allergens")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.allergens
                    ? data?.nutritionalInfo?.allergens
                    : "N/A"}
                </span>
              </p>
              <p className="mb-3">
                {t("item.servingsPerContainer")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.servingPerContainer}
                </span>
              </p>
              <p className="mb-3">
                {t("item.totalFat")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.totalFat}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.transFat")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.transFat}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.cholesterol")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.cholesterol}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.protein")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.protein}g
                </span>
              </p>
              <p className="mb-3">
                {t("item.totalSugar")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.totalSugar}
                </span>
              </p>
              <p className="mb-3">
                {t("item.sodium")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.sodium}
                </span>
              </p>
              <p className="mb-3">
                {t("item.vitaminC")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.vitaminC}
                </span>
              </p>
              <p className="mb-3">
                {t("item.calcium")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.calcium}
                </span>
              </p>
              <p className="mb-3">
                {t("item.potassiumMg")}:{" "}
                <span className="colorDark">
                  {data?.nutritionalInfo?.potassiumPercent}
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
