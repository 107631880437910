import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { UploadIcon } from "../../SvgIcons/allIcons";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import {
  addCategory,
  getCategoryDetails,
  updateCategory,
  uploadCategoryIcon,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const AddCategory = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = searchParams?.get("id");

  const details = useDetails();
  const navigate = useNavigate();

  const onDrop = useCallback((e) => {
    setFieldValue("newPicked", e[0]);
  }, []);

  const onDropRejected = useCallback((e) => {
    setFieldValue("newPicked", e[0]?.file);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "image/*": [] },
    multiple: false,
  });

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    handleSubmit,
    setFieldTouched,
    errors,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      en_name: "",
      es_name: "",
      icons: "",
      newPicked: "",
      assignAll: false,
      locations: [],
    },
    validationSchema: yup.object().shape({
      en_name: yup.string().required().label(t("category.categoryName")).trim(),
      // es_name: yup
      //   .string()
      // .required()
      //   .label(t("category.categoryNameSpanish"))
      //   .trim(),
      locations: yup.array().when("assignAll", {
        is: (value) => !value,
        then: () => yup.array().min(1).label(t("category.location")),
      }),
      newPicked: yup
        .mixed()
        // .when(["icons"], {
        //   is: (value) => !value,
        //   then: () => yup.string().required(t("errorMsg.iconRequired")),
        // })
        .when(([newPicked], schema) => {
          if (newPicked) {
            return yup
              .mixed()
              .test("type", t("errorMsg.fileFormat"), function (value) {
                return (
                  value &&
                  (value.type === "image/jpg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpeg")
                );
              });
          }
          return schema;
        }),
    }),
    onSubmit: (values) => {
      if (!!values?.newPicked) {
        let formData = new FormData();
        formData.append("file", values?.newPicked);
        imageMutation.mutate(formData);
      } else {
        let body = {
          en_name: values?.en_name,
          es_name: values?.es_name,
          assignAll: values?.assignAll,
        };
        if (!!id && values?.icons) {
          body.icons = values?.icons;
        }
        if (!values?.assignAll)
          body.locations = values?.locations?.map((i) => i.value);
        mutation.mutate(body);
      }
    },
  });

  const imageMutation = useMutation({
    mutationFn: (body) => uploadCategoryIcon(body),
    onSuccess: (resp) => {
      setFieldValue("icons", resp?.data?.data?.imageUrl);
      let body = {
        en_name: values?.en_name,
        es_name: values?.es_name,
        icons: resp?.data?.data?.imageUrl,
        assignAll: values?.assignAll,
      };
      if (!values?.assignAll)
        body.locations = values?.locations?.map((i) => i.value);
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updateCategory(details?.companyUID, id, body)
        : addCategory(details?.companyUID, body),
    onSuccess: (resp) => {
      resetForm();
      toastAlert("success", resp?.data?.message);
      navigate(-1);
    },
  });

  const loadLocations = async () => {
    let array = (await details?.userDetails?.userLocations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore: false,
    };
  };

  useQuery({
    queryKey: ["category-details", id],
    queryFn: async () => {
      let resp =
        id && (await getCategoryDetails(details?.companyUID, id, true));
      if (resp?.data?.data) {
        setValues({
          ...values,
          en_name: resp?.data?.data?.en_name,
          es_name: resp?.data?.data?.es_name,
          icons: resp?.data?.data?.icons,
          assignAll: resp?.data?.data?.assignAll,
          locations: resp?.data?.data?.locations?.map((i) => ({
            label: i?.name,
            value: i?._id,
          })),
        });
      }
      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <NavigateBack>
              {!!id ? t("category.editCategory") : t("category.addNewCategory")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="en_name" className="companyLabel">
                  {t("category.categoryName")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="en_name"
                  name="en_name"
                  value={values?.en_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("category.enterCategoryName")}
                />
                <small className="text-danger ms-1">
                  {touched.en_name && errors.en_name}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="es_name" className="companyLabel">
                  {t("category.categoryNameSpanish")}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  id="es_name"
                  name="es_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.es_name}
                  className="commonInput"
                  placeholder={t("category.enterCategoryNameSpanish")}
                />
                <small className="text-danger ms-1">
                  {touched.es_name && errors.es_name}
                </small>
              </div>
            </Col>

            <Col md={12}>
              <div className="mt-3">
                <label htmlFor="name" className="companyLabel">
                  {t("category.location")}
                  <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("category.enterLocation")}
                  id="locations"
                  loadOptions={loadLocations}
                  isClearable={false}
                  defaultOptions={true}
                  isMulti={true}
                  onChange={(e) => setFieldValue("locations", e)}
                  value={values?.locations}
                  onBlur={() => setFieldTouched("locations", true)}
                />

                <small className="text-danger ms-1">
                  {touched.locations && errors.locations}
                </small>
              </div>
            </Col>
            <Col md={12}>
              <div className="customer_Access">
                <div className="access_Child">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="assignAll"
                      name="assignAll"
                      value={values?.assignAll}
                      checked={values?.assignAll}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="assignAll">
                      <span className="fontSize14 ms-2">
                        {t("category.applyToAllfutureLocations")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="file_Upload mt-3">
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  {!values?.newPicked && !isDragActive && (
                    <p className="text-center">{t("category.uploadIcon")}</p>
                  )}
                  {isDragActive ? (
                    <p>{t("globals.dropFilesHere")}</p>
                  ) : (
                    (values?.newPicked || values?.icons) && (
                      <div className="image-preview">
                        <img
                          src={
                            values.newPicked
                              ? URL.createObjectURL(values.newPicked)
                              : values.icons
                          }
                          alt="Preview"
                          className="image-preview"
                        />
                      </div>
                    )
                  )}
                  {!values?.newPicked && !isDragActive && (
                    <button className="userBtn btn-theme fontSize18 uploadFile_Btn">
                      <UploadIcon />{" "}
                      <span className="ms-2">{t("globals.upload")}</span>
                    </button>
                  )}
                </div>
              </div>
              <small className="text-danger ms-1">
                {touched.newPicked && errors.newPicked}
              </small>
            </Col>

            <Col className="mt-4" xl={12}>
              <button
                className="userBtn btn-theme m-0"
                type="button"
                onClick={handleSubmit}
              >
                {!!id ? t("globals.saveChanges") : t("globals.add")}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
