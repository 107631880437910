import React from "react";
import { Accordion, Placeholder } from "react-bootstrap";
import { FeatureUploadLogo } from "../../SvgIcons/allIcons";
import DraggableItem from "./DraggableItem";
import {
  endDateFields,
  labelTypes,
  nutritionFields,
  othersFields,
  startDateFields,
  textFields,
  transitionalDateFields,
} from "../../utils/labelConstants";

const LabelSideBar = () => {
  return (
    <div className="feature_Editor_Child features_Accordion_Parent">
      <DraggableItem
        data={{
          type: "image",
          styles: {
            width: "120",
            height: "75",
          },
          position: {
            x: 0,
            y: 0,
          },
          minWidth: 60,
          minHeight: 50,
        }}
      >
        <div className="features_UploadLogo_Container mb-2">
          <div className="d-flex  align-items-center">
            <FeatureUploadLogo />
            <span className="fontSize16 ms-2">Upload logo</span>
          </div>
          <img src="/images/dropdownListIcon.png" alt="dropdownListIcon" />
        </div>
      </DraggableItem>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="fontSize14">Text</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List" id="text_list">
              {textFields &&
                textFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                        type: labelTypes.text_fields,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="mt-2">
          <Accordion.Header>Start Date</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List">
              {startDateFields &&
                startDateFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                        type: labelTypes.start_date_field,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="mt-2">
          <Accordion.Header>Transitional Date/Phase</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List">
              {transitionalDateFields &&
                transitionalDateFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                        type: labelTypes.transitional_date,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="mt-2">
          <Accordion.Header>End Date</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List">
              {endDateFields &&
                endDateFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                        type: labelTypes.end_date_field,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="mt-2">
          <Accordion.Header>Nutritional Fields</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List">
              {nutritionFields &&
                nutritionFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                        type: labelTypes.nutritional_fields,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="mt-2">
          <Accordion.Header>Others</Accordion.Header>
          <Accordion.Body>
            <ul className="accordion_List">
              {othersFields &&
                othersFields.map((fieldData, field_index) => (
                  <React.Fragment key={`text_fields_${field_index}`}>
                    <DraggableItem
                      data={{
                        ...fieldData,
                      }}
                    >
                      <li>
                        {fieldData.label}
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </li>
                    </DraggableItem>
                  </React.Fragment>
                ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default LabelSideBar;
